<template>
  
  <div class="new">
    <h1>New Post</h1>
    <form v-on:submit.prevent="onSubmit">
      <label for="title">Title</label>
      <input id="title" v-model="title" type="text"/>
      <label for="username">Username</label>
      <input id="username" v-model="username" type="text"/>
      <label for="type">Type</label>
      <select id="type" v-model="type">
        <option disabled value="">Please select one</option>
        <option>Text</option>
        <option>Image</option>
        <option>YouTube</option>
      </select>
      
      <label v-if="type == 'YouTube'" for="content">YouTube ID</label>
      <label v-else-if="type == 'Image'" for="content">Image Link</label>
      <label v-else for="content">Content</label>

      <textarea v-if="type == 'Text'" id="content" v-model="content"></textarea>
      <input v-else id="content" v-model="content" type="text"/>
      <button @click="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      title: "",
      username: "",
      type: "",
      content: ""
    }
  },
  methods: {
    submit(){
      let data = ({
        title: this.title,
        username: this.username,
        type: this.type.toLowerCase(),
        content: this.content
      })
      console.log (data)
      axios
        .post("https://social-page-worker.abramojo02.workers.dev/posts", (data))
        .then(response => {
          if (response.data == "success") { this.$router.push("/") }
        })

    }
  }

}
</script>

<style scoped>
.new{
  max-width: 300px;

  margin: auto;
}
.new form{
  display: flex;
  flex-direction: column;  
  text-align: left;
}

label{
  margin-top: 10px;
}
</style>